<template>
    <div class="missingExamsList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" :rules="searchFormRules" class="demo-form-inline search-form">
                <el-form-item label="试卷列表" prop="examId">
                    <el-select v-model="searchForm.examId" filterable placeholder="请选择" @change="search" style="min-width: 450px;">
                        <el-option v-for="item in examList" :key="item.id" :label="item.name+'('+item.id+')'" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="学号、姓名"  @keyup.enter.native='loadStudentList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='search'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <jsonExcel class="export-excel-wrapper" :data="jsonData" :fields="jsonFields" :beforeGenerate="excelOutput" name="缺考学生名单.xls">
                        <el-button type="danger" size ="mini" icon="search"  :loading="jsonLoading">导出</el-button>
                    </jsonExcel>

                </el-form-item>
            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                <el-table-column label="序号" :formatter="formatterIndex" align='center'></el-table-column>
                <el-table-column prop="student_no" label="学号"  min-width="150" align='center' ></el-table-column>
                <el-table-column prop="student_name" label="姓名"  min-width="150" align='center' ></el-table-column>
                <el-table-column prop="hard_name" label="行政班" align='center' ></el-table-column>

            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

    export default {
        name: 'missingExamsList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:false, isShow:false, showViewer: false, jsonLoading: false,
                examList: [], jsonData: [],
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null, hardId: null, professionId: null, year: null},
                searchFormRules: {
                    examId: [{ required: true, message: '请选择考试', trigger: 'blur' }],
                }, jsonFields: {
                    "学号": {
                        field: "student_no",
                        callback: value =>{
                            return "&nbsp;" + value;
                        }
                    }, "姓名": "student_name", "行政班": "hard_name",

                }

            }
        },
        components:{
            Pagination, ElImageViewer
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadExamList();

	   },
        methods: {
            search(){
                let that = this;
                this.$refs.searchForm.validate((valid) => {
                    if(valid){
                        that.loadStudentList();
                    }
                });

            },
            formatterIndex(row, column, cellValue, index) {
                let base = 0;
                if((this.searchForm.pageNo-1) > 0){
                    base = (this.searchForm.pageNo-1) * this.searchForm.pageSize;
                }
                return base + index+1;
            },
            loadStudentList(){
                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                     examId: mUtils.searchText(this.searchForm.examId),
                   };
                let param = { controllerName: 'exam', methodName: '/missingExamList', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.tableData = list;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            excelOutput(){
                this.jsonLoading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    examId: mUtils.searchText(this.searchForm.examId),
                };
                let param = { controllerName: 'exam', methodName: '/missingExamList', pageNo: 1, pageSize: 9999999, param: search};
                return simpleApi.listWait(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.jsonData = list;
                    }
                    this.jsonLoading = false;
                }).catch((error) => {
                    this.jsonLoading = false;
                    console.log("error")
                });
            },
            loadExamList(){

                let search = { licenceId: this.userInfo.licenceId, schoolId: null, teacherId: null};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["teacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'exam', methodName: '/examList', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadStudentList()
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


